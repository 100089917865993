<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Services</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Services</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <a href="javascript:void(0)" class="btn btn-info" @click="showFilter = !showFilter"><i
              class="fa fa-filter"></i>Filter</a>
            <div class="card-tools">
              <router-link :to="{ name: 'ServiceForm'}" class="btn btn-success"><i class="fas fa-plus"></i> New
              </router-link>
            </div>
          </div>
          <form @submit.prevent="getServices" method="GET" novalidate>
            <div class="card-body" v-if="showFilter">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="name">Name</label>
                    <div class="col-md-9">
                      <input type="text" id="name" class="form-control" v-model="filter.name" placeholder="Name">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="display_name">Display Name</label>
                    <div class="col-md-9">
                      <input type="text" id="display_name" class="form-control" v-model="filter.display_name"
                             placeholder="Display name">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-9 offset-md-3">
                      <button type="submit" class="btn btn-common m-2px">Search</button>
                      <button type="reset" class="btn btn-warning" @click="resetFilter()">Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <div class="card-body table-responsive-md p-0">
            <table class="table">
              <thead>
              <tr>
                <th style="width: 10px">ID</th>
                <th>Service Name</th>
                <th>Display Name</th>
                <th>Is Wallet?</th>
                <th class="text-right">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(service, index) in services.data">
                <td>{{ service.id }}</td>
                <td>{{ service.name }}</td>
                <td>{{ service.display_name }}</td>
                <td>{{ (service.is_wallet) ? 'Yes' : 'No' }}</td>
                <td class="text-right">
                  <router-link :to="{ name: 'ServiceShow', params: { id: service.id }}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <router-link :to="{ name: 'ServiceEdit', params: { id: service.id }}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-pencil-alt"></i>
                  </router-link>
                  <button class="btn btn-danger btn-sm m-2px" @click="destroy(service.id, index)">
                    <i class="fas fa-trash"></i></button>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="p-4" v-if="_.isEmpty(services.data)">
              <div class="alert alert-default-warning" role="alert">
                No data found!
              </div>
            </div>
          </div>
          <div class="card-footer">
            <vue-pagination
              :pagination="services"
              v-if="services.total > services.per_page"
              @paginate="getServices()"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VuePagination from "@/components/partials/PaginationComponent";
import {ServicesAPI} from "@/services/api";

export default {
  name: 'ServiceList',
  metaInfo: {
    title: 'Service List | Dotlines'
  },
  components: {
    VuePagination
  },
  data: () => ({
    services: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0,
      current_page: 1
    },
    showFilter: false,
    filter: {},
    is_loading: false
  }),
  methods: {
    getServices() {
      this.is_loading = true;
      ServicesAPI.index(this.services.current_page, this.filter)
        .then(services => {
          this.services = services;
        }).finally(() => {
          this.is_loading = false;
      });
    },
    resetFilter() {
      this.services.current_page = 1;
      this.filter = {};
      this.showFilter = false;
      this.getServices();
    },
    destroy(id, index) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        confirmButtonText: 'Yes, delete it!',
        icon: 'warning',
        showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          this.is_loading = true;
          ServicesAPI.destroy(id)
            .then(response => {
              this.services.data.splice(index, 1);
              this.services.total--;
              this.$toaster.success(response.message);
            })
            .catch(error => {
              console.log("Could not delete this:: ", error);
              this.$swal.fire('Failed!', 'Failed to delete.', 'error');
            }).finally(() => {
            this.is_loading = false;
          });
        }
      });
    }
  },
  mounted() {
    this.getServices();
  },
}
</script>
